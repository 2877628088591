.story {
  align-items: flex-start;
  background-color: #202020;
  color: #ffffff;
  display: flex;
  flex-flow: column;
  font-size: calc(.375rem + 2vmin);
  height: 86vh;
  padding: 2vh 6vw;
  overflow: auto;
  width: 88vw;
}

.story a {
  color: #08538a;
  cursor: pointer;
  text-decoration: none;
}
