@font-face {
  font-family: 'Geomanist';
  src: url('./assets/geomanist-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/ubuntu-regular.ttf') format('truetype');
}

.App {
  align-items: center;
  background-color: #202020;
  display: flex;
  flex-direction: column;
  font-family: 'Geomanist', sans-serif;
  font-size: calc(.75rem + 2vmin);
  height: 100%;
  justify-content: center;
  margin: auto;
  width: 100%;
}
