.settings {
  bottom: 0;
  position: absolute;
  right: 0;
}

.settings-menu {
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  text-decoration: none;
  width: 50vw;
}

.settings-menu-img {
  max-height: 6vh;
  max-width: 6vw;
}
