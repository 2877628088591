.menu {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 10vh;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  width: 100vw;
}

.menu-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  height: 10vh;
  justify-content: center;
  width: 50vw;
}

.menu-button-img {
  max-height: 6vh;
  max-width: 6vw;
}

.menu-is-active {
  background-color: #404040;
}

.menu-is-inactive {
  background-color: #303030;
}

.menu-list {
  align-items: flex-start;
  background-color: #404040;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: left;
}

.menu-list-item {
  color: #ffffff;
  cursor: pointer;
  height: 4vh;
  padding-left: 6vw;
  text-decoration: none;
  width: 44vw;
}

.menu-list-item a {
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.view {
  bottom: 10vh;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
