@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animation-fade-in {
  animation: fade-in 2s infinite;
  perspective: 1000;
}

@keyframes spin-y {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(720deg);
  }
}

.animation-spin-y {
  animation: spin-y 2s 1;
}

.spin {
  align-items: center;
  background-color: #202020;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  height: 90vh;
  justify-content: center;
  width: 100vw;
}

.spin-container-top {
  height: 35vh;
  width: 100vw;
}

.spin-container-mid {
  align-items: center;
  display: flex;
  height: 20vh;
  justify-content: center;
  width: 100vw;
}

.spin-container-bottom {
  height: 35vh;
  width: 100vw;
}

.spin-hint-text {
  align-items: flex-start;
  display: flex;
  font-size: calc(.375rem + 2vmin);
  justify-content: center;
  margin: 0;
  opacity: 0;
}

.spin-img {
  max-height: 20vh;
  max-width: 60vw;
}
